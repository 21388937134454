/* Hide Calendar Icon In Chrome */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.checkout::after {
  content: "";
  height: 10rem;
}
.checkout {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background-color: var(--light-black);
  background-image: none;
  color: var(--white);
  font-family: var(--poppins-font);
}
.checkout h1 {
  font-family: var(--poppins-font);
  text-transform: uppercase;
  margin: 0 auto;
  font-size: 4rem;
  padding: 1.5rem;
}
.checkout h2 {
  font-weight: 400;
  letter-spacing: 1px;
}

.checkout-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  max-width: 700px;
  margin: 0 auto;
}

/* SUMMARY */
.checkout-summary {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.checkout-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.image-container {
  min-height: 8rem;
  max-height: 100%;
}
.checkout-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.checkout-item .checkout-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  gap: 0.5rem;
}
/* PERSONAL INFORMATION */
.checkout-personal-information,
.checkout-personal-information form,
.checkout-choose-delivery,
.checkout-choose-delivery form,
.checkout-choose-payment,
.delivery,
.promo-code {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.checkout-personal-information input[type="text" i],
.checkout-choose-payment input,
.promo-code input {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
  border: 1px solid var(--light-gray);
  padding: 1rem;
  background-color: transparent;
  font-weight: 400;
  font-size: 1rem;
  max-width: 80%;
  width: 80%;
}

/* CHOOSE DELIVERY */
.checkout-choose-delivery form label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 50%;
}
.takeaway[type="radio" i],
.delivery[type="radio" i] {
  -moz-appearance: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid var(--white);
  padding: 0.5rem;
  border-radius: 50%;
}
.takeaway[type="radio" i]:checked,
.delivery[type="radio" i]:checked {
  border: 1px solid var(--white);
  background-color: var(--pizza-red);
}
.takeaway:checked,
.delivery:checked {
  width: 1rem;
}

.takeaway-option,
.delivery-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.takeaway-option svg {
  width: 1rem;
  height: 1rem;
}
/* PROMOCODE */
.promo-code p {
  cursor: pointer;
  color: var(--white);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
  transition: all ease-in-out 0.2s;
  text-decoration: underline;
}
.promo-code p:hover {
  color: var(--yellow);
  transition: all ease-in-out 0.2s;
}

input[type="password"] {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
}

/* CART TOTALS */
.cart-totals {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.checkout-carttotals .cart-carttotals .totals-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--poppins-font);
  color: var(--whitish-gray);
  text-transform: uppercase;
  padding: 1rem;
  font-size: 1.2rem;
  gap: 1rem;
}
.checkout-carttotals .totals-content > * {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.checkout-carttotals .totals-content h4 {
  font-weight: 300;
}
.checkout-carttotals .totals-content p {
  font-weight: 600;
  color: var(--yellow);
}
.checkout-choose-payment input[type="date"] {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
}

/* BUTTONS */
.checkout-interaction-btns {
  display: flex;
  flex-direction: column;
  max-width: 65%;
  width: 100%;
  align-items: flex-start;
  gap: 1rem;
}

.checkout-backtomenu-btn {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
  border: none;
  text-align: center;
  transition: all ease-in-out 0.2s;
  letter-spacing: 1px;
}

.checkout-backtomenu-btn:hover {
  color: var(--yellow);
  transition: all ease-in-out 0.2s;
}

.checkout-backtomenu-btn {
  text-decoration: underline;
  border: none;
  font-size: 0.8rem;
}
.checkout-choose-payment label {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 700px) {
  .checkout-cart-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    align-items: baseline;
  }
  .checkout-choose-payment label {
    flex-direction: row;
    justify-content: space-between;
  }
}
