.contact-section-comp {
  width: 90%;
  margin: 0 auto;
}

.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  font-family: var(--poppins-font);
  width: 100%;
}
.about-us h1,
.about-us .sub-title {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.main-title {
  font-size: 3rem;
  text-transform: uppercase;
}

.about-us p {
  font-size: 1.4rem;
}

.section-one,
.section-two,
.section-three {
  display: flex;
  flex-direction: column;
}
.section-four,
.section-five {
  display: grid;
  grid-template-columns: 1fr;
}
.sub-title {
  color: var(--pizza-red);
  font-family: var(--nothing-font);
  letter-spacing: 1px;
}

.aboutus-img-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.aboutus-img-grid img {
  width: 100%;
  min-height: 100%;
  height: 16rem;
  max-height: 100%;
  object-fit: cover;
}

.section-one,
.section-two {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Section 1 */
.section-one {
  background-image: url("../../assets/images/about-us/section-one.jpeg");
}
.section-one::after,
.section-two::after {
  content: "";
  background-color: var(--pizza-red);
  opacity: 0.3;
  width: 100%;
  height: 1px;
  bottom: 0;
  z-index: 2;
}
.section-one-content,
.section-two-content {
  position: relative;
  z-index: 1;
  width: 90%;
  text-align: left;
  margin: 0 auto;
}
.dark-glass {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.411);
  backdrop-filter: blur(1px);
}

/* Section 2 */
.section-two {
  background-image: url("../../assets/images/about-us/section-two.jpeg");
}

.black-glass {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.829);
  backdrop-filter: blur(3px);
}

.section-three-content {
  margin: 0 auto;
}

.section-three-img-grid {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
}
.section-three-img-grid img {
  min-width: 10rem;
  width: 100%;
  min-height: 100%;
  height: 20rem;
  max-height: 100%;
  border-radius: 15px;
}

.img-glass {
  width: 100%;
  height: 20rem;
  bottom: 0;
  background-image: url("../../assets/images/about-us/section-three.jpeg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  opacity: 0.7;
}

.section-four img,
.section-five img {
  width: 100%;
  min-height: 100%;
  height: 20rem;
  max-height: 100%;
  opacity: 0.8;
  object-fit: cover;
}
.section-one-content,
.section-two-content,
.section-three-content,
.section-four-content,
.section-five-content {
  padding: 1rem;
}

/***************  RESPONSIVE ***************/

@media screen and (min-width: 700px) {
  .section-one-content,
  .section-two-content,
  .section-three-content,
  .section-four-content,
  .section-five-content {
    padding: 2rem;
  }
  .aboutus-img-grid {
    flex-direction: row;
    justify-content: center;
  }
}

@media screen and (min-width: 1025px) {
  .section-one-content,
  .section-two-content,
  .section-three-content {
    width: 70%;
  }
  .section-three-img-grid {
    flex-direction: row;
  }
  .section-four,
  .section-five {
    grid-template-columns: repeat(2, 1fr);
  }
}
/***************  RESPONSIVE ***************/
@media screen and (min-width: 1110px) {
  .section-one-content,
  .section-two-content,
  .section-three-content {
    width: 50%;
  }
}
