.password-recovery-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: var(--light-black);
  min-height: 100vh;
}

.pass-recovery-title {
  color: var(--white);
  font-family: var(--poppins-font);
  padding: 2rem;
  margin: 0 auto;
  text-align: center;
}

.password-recovery-main h1 {
  text-transform: uppercase;
  font-size: 2rem;
}

.pass-recovery-section {
  background-color: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  font-family: var(--poppins-font);
  font-weight: 400;
  align-items: left;
  justify-content: space-between;
}

.forgot-pass-submit-section {
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 90%;
  margin: 0 auto;
}

.forgot-pass-input {
  border: 1px solid #fac56425;
  background-color: transparent;
  padding: 2rem;
  width: -webkit-fill-available;
  border-radius: 10px;
}
.forgot-pass-input::placeholder {
  color: var(--white);
  font-family: var(--poppins-font);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.forgot-pass-input:focus-visible {
  outline: 1px solid var(--yellow);
}
.forgot-pass-btn {
  cursor: pointer;
  position: absolute;
  background-color: var(--yellow);
  border: 1px solid transparent;
  padding: 2rem;
  color: var(--light-black);
  font-family: var(--poppins-font);
  font-weight: 400;
  letter-spacing: 1px;
  border-radius: 0 10px 10px 0;
  right: 0;
  transition: all ease-in-out 0.2s;
}

.forgot-pass-btn:hover {
  opacity: 0.8;
  transition: all ease-in-out 0.2s;
}
.forgot-email {
  cursor: pointer;
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  text-align: center;
  transition: all ease-in-out 0.2s;
}
.forgot-email:hover {
  color: var(--yellow);
  transition: all ease-in-out 0.2s;
}
