.blog::before {
  content: "";
  height: 2rem;
}
.blog::after {
  content: "";
  height: 10rem;
}
.blog {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-image: url("../../assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.blog-section h1,
.blog-intro,
.blog-posts {
  font-family: var(--poppins-font);
}

.blog-section h1,
.blog-posts h3 {
  color: var(--white);
}
.blog-section h1 {
  font-size: 4rem;
}
.blog-intro {
  color: var(--whitish-gray);
  font-size: 1.3rem;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.blog-posts {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 4rem;
  width: 90%;
  margin: 0 auto;
  max-width: 1440px;
}

.blog-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.blog-posts h3 {
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 400;
}

.blog-post {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
}

.blog-details {
  color: var(--whitish-gray);
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.blog-snippet {
  color: var(--whitish-gray);
}

/***************  RESPONSIVE ***************/

@media screen and (min-width: 700px) {
  .blog-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1110px) {
  .blog-posts {
    grid-template-columns: repeat(3, 1fr);
  }
}
